import { BASE_DOMAINS } from "../const/domains";
import { PREVIEW_OPTIONS } from "../enums/campaign-build.enums";
import { config } from "../config";

const options = Object.values(PREVIEW_OPTIONS).join('|');

export const getPageIdFromPreviewUrl = (url: string): string | null => {
  // RegExp pattern matches draft/<pageId> or published/<pageId>
  const regExPageId = new RegExp(`(?:${options})\/([A-Za-z0-9]+)`);
  const match = url.match(regExPageId);
  return match ? match[1] : null;
}

export const replacePageIdInPreviewUrl = (url :string, newPageId: string): string => {
  // RegExp pattern matches /page-preview/draft/<pageId> or /page-preview/published/<pageId> 
  // if there's additional stuff after <pageId> separated by special character, it will still only replace the pageId
  const regExPageId = new RegExp(`(\/?page-preview\/(?:${options})\/)([A-Za-z0-9]+)(.*)`);
  return url.replace(regExPageId, `$1${newPageId}$3`);
}

export interface IUrlPattern {
  isCustom: boolean;
  campaignSlug: string;
  pageSlug?: string;
  pageId?: string;
}

export const identifyPublishedUrlPattern = (url: string, host: string): IUrlPattern => {
  // Determine url pattern
    // 1- <host>/ --> landing page custom domain
    // 2- <host>/<campaignSlug> --> landing page for custom and regular domain 
    // 3- <host>/<pageSlug> --> custom domain without campaign slug page (most cases for custom domains)
    // 4- <host>/<campaignSlug>/<pageSlug> --> regular domain page & custom domain with campaign slug page
    // 5- <host>/<campaignSlug>/page/<pageId>

  if (config.ENV === 'local') {
    host = host.substring(0, host.indexOf(':'));
  }
  
  const urlPattern: IUrlPattern = {
    isCustom: !BASE_DOMAINS.includes(host),
    campaignSlug: ''
  }

  if (url === '/') { return urlPattern; }

  const cleanUrl = url.replace(/^\/?(.*?)\/?$/, '$1'); // remove prefix and trailing / if any
  const urlParts = cleanUrl.split('/');

  switch (urlParts.length) {
    case 1:
      return {
        ...urlPattern,
        campaignSlug: urlParts[0],
        pageSlug: ''
      }
    case 2:
      return {
        ...urlPattern,
        campaignSlug: urlParts[0],
        pageSlug: urlParts[1]
      }
    case 3:
      if (urlParts[1] === 'page') {
        return {
          ...urlPattern,
          campaignSlug: urlParts[0],
          pageId: urlParts[2]
        }
      }
  }
}