import * as React from 'react';
import { Logger } from 'pino';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Page404 from '../404';
import { config } from '../../config';
import PageTemplate from '../../templates/page';
import { navigateToErrorPage } from '../../routers';
import {
  BUILDER_CONTENT_ROUTE,
  PUBLIC_API_CONTENT_ROUTE,
  PREVIEW_OPTIONS,
} from '../../enums/campaign-build.enums';
import { IPageToBuild, PageProps } from '../../interfaces/campaign-build.interfaces';
import { getMiraContent, getBuilderContent } from '../../services/mira-api-legacy';
import { getPageById } from '../../services/pageService';
import DoNotShareAlert from '../../components/do-not-share-alert';
import { LoggerService } from '../../util/logger/logger-service.class';
import {
  convertMapHeader
} from '../../util';
import {
  getPageIdFromPreviewUrl,
  replacePageIdInPreviewUrl
} from '../../util/urlPattern';
import packageJson from '../../../package.json';

dayjs.extend(utc);
dayjs.extend(timezone);

const logger = LoggerService.getLogger({
  packageName: packageJson.name,
  packageVersion: packageJson.version,
  logPretty: config.LOG_PRETTY_PRINT
});

declare global {
  interface Window {
    ssr: boolean;
  }
}

const PageSelectorContainer = styled.div`
  border-bottom: 1px solid lightGray;
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 14px 0;
`;

const Select = styled.select`
  height: 32px;
  min-width: 150px;
  text-align: center;
`;

const PageContent = (props: PageProps) => {
  const [selectedPageId, setSelectedPageId] = React.useState<string>(props.serverData?.pageContext?.pageId || '');

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const pageId = event.target.value;
    const newPath = replacePageIdInPreviewUrl(location.pathname, pageId);
    location.href = `${location.origin}${newPath}`;
    setSelectedPageId(pageId);
  };

  const handleRedirectApp = (): void => {
    try {
      if (window.location.search.length) {
        const params = querystring.parse(window.location.search.substring(1));
        if (params.error) {
          // creates a cookie that can be seen by any umusic subdomain
          setCookie('redirect-app-error', params.error.toString(), 30, '.umusic.com');
        }
      }
      window.close();
    } catch (error) {
      logger.info(`Error redirect app`);
      logger.error(error)
    }
  };

  React.useEffect(() => {
    if (props?.serverData?.isRedirectApp) {
      handleRedirectApp();
      return;
    }
  }, []);

  if (props.serverData?.status !== 200) {
    if (props.serverData?.headers.location === '/404') {
      return <Page404 />
    } else {
      location.href = props.serverData?.headers ? props.serverData?.headers.location : '/404';
    }
    return;
  }

  return (
    <>
      <PageSelectorContainer>
        <Select value={selectedPageId} onChange={handleOnChange}>
          {props.serverData.previewPages.map((page: IPageToBuild) => (
            <option value={page.id} key={page.id}>
              {page.name}
            </option>
          ))}
        </Select>
        <DoNotShareAlert />
      </PageSelectorContainer>
      <PageTemplate {...props?.serverData} />
    </>
  );
};

export async function getServerData(context) {
  let url = context.params['*'];
  url = url.split('?')[0];

  // listen to all http requests
  // exclude webpack, .io, .ico, .json, .txt, .html files
  if (/(webpack|\.io|\.ico|\.json|\.txt|\.html|\..*)/.test(url) || !url) {
    return navigateToErrorPage();
  }
  
  logger.info(`url: ${url}`);
  context.headers =  convertMapHeader({ context, headersToExclude: ['cookie']});
  logger.info(`headers: ${JSON.stringify(context.headers,null,2)}`);

  if (
    !url
    && (
      !url.startsWith(`${PREVIEW_OPTIONS.Draft}/`)
      || !url.startsWith(`${PREVIEW_OPTIONS.Published}/`)
    )
  ) {
    logger.error('Missing url or url didn\'t match draft / publish preview patterns');
    return navigateToErrorPage();
  }

  // logic for Spotify In-App
  // Check if request url includes "redirect-app", isRedirectApp will catch querystring param error, send it in a message and close window
  if (url.includes('redirect-app')) {
    return {
      props: {
        isRedirectApp: true,
      },
    };
  }

  const isDraft = url.includes(`${PREVIEW_OPTIONS.Draft}/`);
  const pageId = getPageIdFromPreviewUrl(url);

  if (!pageId) {
    logger.error('Request path did not match expected pattern, pageId could not be extracted.');
    return navigateToErrorPage();
  }
  
  const result = await getPageById({ isDraft, pageId, logger});

  if (
    !result
    || !result?.page
    || !result?.campaign
  ) {
    logger.error('Preview page / campaign data didn\'t exist.');
    return navigateToErrorPage();
  }

  // Uncomment if we wanted to switch to listing page url and not fetch all page content for no reason
  // other than getting the page name...
  // let previewPages = result.campaign.data.router.routes.map((route: any) => {
  //   return {
  //     id: route.default,
  //     name: route.url
  //   }
  // });;
  let previewPages: IPageToBuild[] = [];
  if (!isDraft) {
    const pageIds = result.campaign.data.router.routes.map((route: any) => route.default);

    if (pageIds.length > 0) {
      const promises = pageIds.map((pageId: string) => getMiraContent(PUBLIC_API_CONTENT_ROUTE.PagesVersion, `?id=${pageId}&version=${result.campaign.version}`, logger));

      previewPages = (await Promise.all(promises)).reduce(
        (acc: IPageToBuild[], currentValue: any) => {
          if (currentValue?.page && result?.campaign) {
            const campaignData = result.campaign.data;
            const campaignSlug = campaignData.slug;
            const campaignRoutes = campaignData.router.routes;

            currentValue.page.slug = `${campaignSlug}${
              campaignRoutes.find(
                (route: any) => route.default === currentValue.page.id
              )?.url || ''
            }`;

            acc.push({
              ...currentValue.page,
            });
          }
          return acc;
        },
        []
      );
    }
  } else {
    previewPages = await getBuilderContent(BUILDER_CONTENT_ROUTE.Page, `query.data.campaign.id=${result.campaign.id}&query.published.$ne=archived`, isDraft, logger);
  }

  return {
    props: {
      status: 200,
      previewPages,
      pageContext: {
        pageId: result.page.id,
      },
      data: {
        allBuilderModels: {
          page: [
            {
              content: {
                id: result.page.id,
                data: {
                  ...result.page.data,
                  campaign: {
                    id: result.campaign.id,
                    value: result.campaign,
                  },
                },
              },
              localFiles: [],
            },
          ],
        },
      },
      children: null,
    },
  };
}

export default PageContent;
