enum BUILDER_CONTENT_ROUTE {
  Campaign = 'campaign',
  Page = 'page',
};

enum PUBLIC_API_CONTENT_ROUTE {
  Campaign = 'public/campaign',
  CampaignVersion = 'public/campaign/version',
  Page = 'public/page',
  PagesVersion = 'public/pages/version',
};

enum PAGE_PREVIEW_TYPE {
  Draft = 'draft/',
  Published = 'published/'
};

export const PREVIEW_OPTIONS = {
  Draft: 'draft',
  Published: 'published'
} as const

type PreviewOptions = typeof PREVIEW_OPTIONS;
type previewOptionsKeys = keyof PreviewOptions;
export type PREVIEW_OPTIONS_TYPE = PreviewOptions[previewOptionsKeys];


export {
  BUILDER_CONTENT_ROUTE,
  PUBLIC_API_CONTENT_ROUTE,
  PAGE_PREVIEW_TYPE
};
