import { config } from "../config";

const PREVIEW_DOMAIN = 'preview.mira.umusic.com';

const DOMAINS = [
  PREVIEW_DOMAIN,
  'competition.umusic.com',
  'digital.umusic.com',
  'live.umusic.com',
  'party.umusic.com',
  'play.umusic.com',
  'presave.umusic.com',
  'stream.umusic.com',
  'imperial.umusic.com',
  'signup.umusic.com',
  'playlist.umusic.com'
];

const PROD_ONLY_DOMAINS = [
  'link.fans',
  'umj.umusic.com',
  'digital.ingrooves.com',
  'giveaway.ingrooves.com',
  'newmusic.ingrooves.com',
  'digital.defjam.com'
];

const envSpecificEnvs = config.ENV === 'prod' ? DOMAINS : DOMAINS.map( domain => `${config.ENV}.${domain}`);

export const BASE_DOMAINS = [
  ...envSpecificEnvs,
  ...PROD_ONLY_DOMAINS
];

export const HOST_TYPE = {
  CUSTOM: 'custom',
  BASE: 'base'
};